<h3>Player Identification Methods</h3>
<ng-container *ngIf="internationalOrganisationForm">
	<form
		[formGroup]="internationalOrganisationForm"
		class="container column">
		<div class="top-row grid-row grid-container">
			<div class="grid-item"></div>
			<div class="grid-item filler"></div>
			<div class="grid-item right">
				<div class="inner-item">Default?</div>
				<div class="inner-item delete filler">Delete</div>
			</div>
		</div>
		<div class="static-row grid-row container row">
			<span>Names</span>
			<div class="grid-item filler"></div>
			<div class="grid-item right">
				<mat-checkbox
					formControlName="defaultNames"
					(change)="onDefaultChange(true, undefined)"
					class="inner-item"></mat-checkbox>
				<div class="delete-wrapper">
					<!-- <button
					class="inner-item delete"
					mat-flat-button>
					<mat-icon>delete</mat-icon>
				</button> -->
					<mat-icon class="no-delete">block</mat-icon>
				</div>
			</div>
		</div>

		<div
			formArrayName="choices"
			class="additional-choices">
			<div
				*ngFor="let choice of choices.controls; let i = index"
				[formGroupName]="i"
				class="choice-wrapper grid-row">
				<div class="top-level grid-item">
					<mat-form-field>
						<mat-label>Select Organisation</mat-label>
						<mat-select
							formControlName="selectedOrganisation"
							(selectionChange)="onOrgChange($event.value, i)">
							<mat-option
								*ngFor="let org of organisations"
								[value]="org.value"
								>{{ org.name }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</div>
				<div
					*ngIf="selectedOrganisations[i] && getSubOrgsForChoice(i).length > 0"
					class="sub-level grid-item">
					<mat-form-field>
						<mat-label> Select Sub-Organisation</mat-label>
						<mat-select
							*ngIf="selectedOrganisations"
							formControlName="selectedSubOrganisation">
							<mat-option
								*ngFor="let subOrg of getSubOrgsForChoice(i); let i = index"
								[value]="subOrg.value"
								>{{ subOrg.name }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="grid-item right">
					<mat-checkbox
						class="inner-item-item"
						formControlName="setDefault"
						(change)="onDefaultChange(false, i)"></mat-checkbox>
					<div class="delete-wrapper">
						<button
							class="inner-item delete"
							mat-flat-button
							(click)="removeChoice(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
		<button
			class="add"
			mat-flat-button
			(click)="addChoice()">
			Add
		</button>
		<!-- <div class="container row button-wrappper">
		<button
			class="save"
			mat-flat-button
			color="primary"
			(click)="getPlayerIdValues()">
			Save
		</button>
	</div> -->
	</form>
</ng-container>
