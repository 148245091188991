<div class="main container column">
	<div class="container row head">
		<div class="container row search">
			<mat-form-field
				class="search-row"
				[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
				<mat-label>Type to search players</mat-label>
				<input
					#input
					matInput
					(keyup)="applyFilter($event)" />
			</mat-form-field>
			<button
				[ngClass]="{ 'ibescore-theme': applyIbescoreTheme }"
				class="add-row"
				mat-flat-button
				color="primary"
				(click)="onPlayerAdd()">
				+ Player
			</button>
			<button
				*ngIf="dataSource.filter !== ''"
				class="clear-filter"
				mat-flat-button
				color="accent"
				(click)="clearFilter(input)">
				Clear
			</button>
		</div>
	</div>
	<div class="table-wrapper">
		<!-- <ng-container *ngIf="dataSource.data && dataSource.data.sort"> -->
		<table
			[hidden]="!dataSource.data && !table && dataSource.data.length < 1"
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			class="mat-elevation-z8"
			matSort
			mat-table
			[dataSource]="dataSource">
			<!-- Number Column -->
			<ng-container matColumnDef="intKey">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					#
				</th>
				<td
					mat-cell
					*matCellDef="let player">
					{{ player?.intKey || '' }}
				</td>
			</ng-container>

			<!-- Player Name Column -->
			<ng-container matColumnDef="name">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					Name
				</th>
				<td
					mat-cell
					*matCellDef="let player">
					{{ player?.name || '' }}
				</td>
			</ng-container>

			<!-- Player Email Column -->
			<ng-container matColumnDef="email">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					Email
				</th>
				<td
					mat-cell
					*matCellDef="let player">
					{{ player?.email || '' }}
				</td>
			</ng-container>

			<!-- Player Phone Column -->
			<ng-container matColumnDef="telephone">
				<th
					mat-header-cell
					*matHeaderCellDef>
					Telephone
				</th>
				<td
					mat-cell
					*matCellDef="let player">
					{{ player?.phone || '' }}
				</td>
			</ng-container>

			<!-- Player Ids Column -->
			<ng-container matColumnDef="id">
				<th
					mat-header-cell
					*matHeaderCellDef>
					player IDs
				</th>
				<td
					mat-cell
					*matCellDef="let player">
					{{ getIds(player?.id)?.join(', ') || '' }}
				</td>
			</ng-container>

			<!-- Last Play Column -->
			<ng-container matColumnDef="lastplay">
				<th
					mat-header-cell
					*matHeaderCellDef>
					Last Play
				</th>
				<td
					mat-cell
					*matCellDef="let player">
					{{ player?.lastplay ? player.lastplay[0]?.$?.date : '' }}
				</td>
			</ng-container>

			<!-- Added Date Column -->
			<ng-container matColumnDef="adddate">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					Added
				</th>
				<td
					mat-cell
					*matCellDef="let player">
					{{ player.$.adddate || '' }}
				</td>
			</ng-container>
			<!--  Delete Column -->
			<ng-container matColumnDef="delete">
				<th
					mat-header-cell
					*matHeaderCellDef>
					Delete
				</th>
				<td
					class="delete"
					mat-cell
					*matCellDef="let player">
					<mat-icon (click)="onDelete(player); $event.stopPropagation()"
						>delete</mat-icon
					>
				</td>
			</ng-container>

			<tr
				class="header-row"
				mat-header-row
				*matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
				(click)="onRowClick(row)"></tr>
			<!-- <tr
				class="mat-row mat-no-data-row"
				*matNoDataRow>
				<td
					class="mat-cell"
					mat-cell
					colspan="4">
					No games matching your search term "{{ input }}"
				</td>
			</tr> -->
		</table>
		<!-- </ng-container> -->
	</div>
	<mat-paginator
		class="mat-elevation-z8"
		#paginator
		[pageIndex]="0"
		[pageSizeOptions]="[20, 40, 100, dataSource.data.length]"
		showFirstLastButtons
		aria-label="Select page of players">
	</mat-paginator>

	<ng-container *ngIf="tabSelected && isLoading">
		<mat-spinner></mat-spinner
	></ng-container>
</div>
