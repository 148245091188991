<div
	class="container column main"
	[ngClass]="currentBreakpoint">
	<h4>Update to and from BridgeWebs or your computer/device</h4>

	<div
		class="container column center database-operations"
		[ngClass]="currentBreakpoint">
		<div
			class="container column center section import-export"
			[ngClass]="currentBreakpoint + (csvMapping ? ' expand' : '')">
			<div
				class="block"
				[ngClass]="currentBreakpoint + (csvMapping ? ' expand' : '')">
				<h3 class="import-export">Import / Export to and from file</h3>
				<div
					class="container column center inner"
					[ngClass]="currentBreakpoint + (csvMapping ? ' expand' : '')">
					<div class="container column center">
						<h4>Upload from disk</h4>
						<!-- <app-upload-file
						(selectedFilesChange)="onFileListChange($event)"
						(upload)="(selectedFile)"></app-upload-file> -->

						<ng-container *ngIf="!isDesktop">
							<div class="desktop-warning container row center">
								<h3>
									Not available on mobile or tablet devices. Please switch to a
									desktop or laptop to import a database file.
								</h3>
							</div>
						</ng-container>
						<ng-container *ngIf="isDesktop">
							<app-database-import
								(signalMapping)="
									triggerMappingContainer($event)
								"></app-database-import>
						</ng-container>
					</div>
				</div>
			</div>
			<!-- <div class="block">
				<div class="container download column center">
					<h4>Download to computer / device</h4>
					<button
						class="form-button download"
						color="primary"
						mat-flat-button
						(click)="onDatabaseExport()">
						Download
					</button>
				</div>
			</div> -->
		</div>
		<div class="container column center section download">
			<div
				class="block"
				[ngClass]="currentBreakpoint + (csvMapping ? ' expand' : '')">
				<div
					class="container column center inner"
					[ngClass]="currentBreakpoint + (csvMapping ? ' expand' : '')">
					<h3 class="download">Download to computer / device</h3>
					<button
						class="form-button download"
						color="primary"
						mat-flat-button
						(click)="onDatabaseExport()">
						Download
					</button>
				</div>
			</div>
		</div>

		<div class="container column section center delete">
			<div class="block">
				<h3>Clear Database</h3>
				<div
					class="container column center inner"
					[ngClass]="currentBreakpoint">
					<span>This will delete the entire player database. Use carefully.</span>
					<button
						mat-flat-button
						color="warn"
						(click)="onDelete()">
						Delete
					</button>
				</div>
			</div>
		</div>
		<div class="container column section center bridgewebs">
			<div class="block">
				<h3>BridgeWebs</h3>
				<div
					class="container row inner"
					[ngClass]="currentBreakpoint">
					<form
						[formGroup]="bwUpdateForm"
						class="bridgewebs-form">
						<div class="container column form-inner">
							<h4>Update From BridgeWebs</h4>
							<mat-checkbox formControlName="contactInfo"
								>Include contact info</mat-checkbox
							>
							<mat-checkbox formControlName="excludePlayers"
								>Do not include players not found on BridgeWebs</mat-checkbox
							>
							<mat-form-field class="bridgewebs account-no">
								<mat-label>Account Name</mat-label>
								<input
									matInput
									formControlName="bwAccount" />
							</mat-form-field>
							<mat-form-field class="bridgewebs pass">
								<mat-label>Password</mat-label>
								<input
									[type]="hide ? 'password' : 'text'"
									matInput
									formControlName="bwPass" />
								<mat-icon
									matSuffix
									(click)="hide = !hide"
									>{{ hide ? 'visibility_off' : 'visbility_on' }}</mat-icon
								>
							</mat-form-field>
							<ng-container *ngIf="bwUpdateForm.valid">
								<span
									><h3>
										Warning. This will completely over write the current database
									</h3></span
								>
							</ng-container>
							<ng-container *ngIf="bridgeWebUpdating">
								<div class="container spinner">
									<mat-spinner></mat-spinner>
								</div>
							</ng-container>
							<button
								class="form-button upload"
								[disabled]="bwUpdateForm.invalid"
								mat-flat-button
								color="primary"
								(click)="updateFromBridgeWebs()">
								{{ bwButton }}
							</button>
						</div>
					</form>
					<!-- <form [formGroup]="bwDownloadForm">
					<div class="container column form-inner">
						<h4>Update To BridgeWebs</h4>
						<mat-form-field class="bridgewebs account-no">
							<mat-label>Account Name</mat-label>
							<input
								matInput
								formControlName="bwAccount" />
						</mat-form-field>
						<mat-form-field class="bridgewebs pass">
							<mat-label>Password</mat-label>
							<input
								[type]="hide ? 'password' : 'text'"
								matInput
								formControlName="bwPass" />
							<mat-icon
								matSuffix
								(click)="hide = !hide"
								>{{ hide ? 'visibility_off' : 'visbility_on' }}</mat-icon
							>
						</mat-form-field>
						<button
							class="form-button download"
							[disabled]="bwDownloadForm.invalid"
							mat-flat-button
							color="primary"
							(click)="bridgeWebsDownload()">
							Download
						</button>
					</div>
				</form> -->
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template
	#deleteDialog
	let-dialogRef>
	<div class="container column center delete-dialog">
		<h3>Are you sure you want to delete?</h3>
		<div class="container row button-wrapper">
			<button
				mat-flat-button
				color="warn"
				(click)="closeDeleteDialog(true)">
				Delete
			</button>
			<button
				mat-flat-button
				color="accent"
				(click)="closeDeleteDialog(false)">
				Cancel
			</button>
		</div>
	</div>
</ng-template>
