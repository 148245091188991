<div class="main container column">
	<div class="container row head">
		<div class="container row search">
			<mat-form-field
				class="search-row"
				[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
				<mat-label>Search teams</mat-label>
				<input
					#input
					matInput
					(keyup)="applyFilter($event)" />
			</mat-form-field>
			<button
				(click)="onTeamAdd()"
				class="add-row team"
				mat-flat-button
				color="primary">
				+ Team Name
			</button>
			<button
				*ngIf="dataSource.filter !== ''"
				class="clear-filter"
				mat-flat-button
				color="accent"
				(click)="clearFilter(input)">
				Clear
			</button>
		</div>
	</div>
	<div class="table-wrapper">
		<ng-container *ngIf="dataSource.data">
			<table
				[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
				class="mat-elevation-z8"
				matSort
				mat-table
				[dataSource]="dataSource">
				<!-- Number Column -->
				<ng-container matColumnDef="intKey">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header="intKey">
						#
					</th>
					<td
						mat-cell
						*matCellDef="let team">
						{{ team.intKey || '' }}
					</td>
				</ng-container>
				<!-- Team Column -->
				<ng-container matColumnDef="name">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header="name">
						Team
					</th>
					<td
						mat-cell
						*matCellDef="let team">
						{{ team?.name || '' }}
					</td>
				</ng-container>
				<!-- Last Used Column -->
				<ng-container matColumnDef="lastUsed">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header="lastUsed">
						Last Used
					</th>
					<td
						mat-cell
						*matCellDef="let team">
						{{ team?.lastplay[0]?.$?.date || '' }}
					</td>
				</ng-container>
				<!-- Date Added Column -->
				<ng-container matColumnDef="added">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header="added">
						Date Added
					</th>
					<td
						mat-cell
						*matCellDef="let team">
						{{ team.$?.adddate || '' }}
					</td>
				</ng-container>
				<!-- Date Added Column -->
				<ng-container matColumnDef="delete">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header="delete">
						Delete
					</th>
					<td
						class="delete"
						mat-cell
						*matCellDef="let team">
						<mat-icon (click)="onDelete(team); $event.stopPropagation()"
							>delete</mat-icon
						>
					</td>
				</ng-container>
				<tr
					class="header-row"
					mat-header-row
					*matHeaderRowDef="displayedColumns"></tr>
				<tr
					(click)="onRowClick(row)"
					mat-row
					*matRowDef="let row; columns: displayedColumns"></tr>
				<!-- <tr
					mat-row
					*matNoDataRow>
					<td
						mat-cell
						colspan="4">
						No games matching your search term "{{ input.value }}"
					</td>
				</tr> -->
			</table>
		</ng-container>
		<mat-paginator
			#paginator
			class="mat-elevation-z8"
			[pageSizeOptions]="[20, 40, 100, dataSource.data.length]"
			showFirstLastButtons
			aria-label="Select page of teams">
		</mat-paginator>
		<ng-container *ngIf="tabSelected && isLoading">
			<mat-spinner></mat-spinner
		></ng-container>
	</div>
</div>
