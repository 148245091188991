<div class="container column center dialog-main">
	<h3>Download HTML / PDF</h3>
	<form [formGroup]="htmlPdfForm">
		<div class="form container column center">
			<mat-form-field>
				<mat-label>Event Name</mat-label>
				<input
					matInput
					formControlName="eventName" />
			</mat-form-field>
			<mat-form-field>
				<mat-label>Director Name</mat-label>
				<input
					matInput
					formControlName="directorName" />
			</mat-form-field>
			<mat-form-field>
				<mat-label>Comments</mat-label>
				<input
					matInput
					formControlName="comments" />
			</mat-form-field>
			<!-- <div class="radio container row">
				<mat-radio-group
					formControlName="fileType"
					aria-label="Select a file type option">
					<mat-radio-button value="html">HTML</mat-radio-button>
					<mat-radio-button value="pdf">PDF</mat-radio-button>
				</mat-radio-group>
			</div> -->
			<div class="checkbox container row">
				<div class="container column checkbox-col">
					<mat-checkbox formControlName="rankings">Rankings</mat-checkbox>
					<mat-checkbox formControlName="scorecards">Scorecards</mat-checkbox>
				</div>
				<div class="container column checkbox-col">
					<mat-checkbox formControlName="matrix">Matrix</mat-checkbox>
					<mat-checkbox formControlName="hands">Hands</mat-checkbox>
				</div>
			</div>
			<div class="button-wrapper container row">
				<button
					[disabled]="!htmlPdfForm.valid"
					mat-flat-button
					color="accent"
					(click)="handleRequest({ preview: true })">
					Preview
				</button>

				<button
					[disabled]="!htmlPdfForm.valid"
					mat-flat-button
					color="primary"
					(click)="handleRequest({ download: true })">
					Download
				</button>
			</div>
		</div>
	</form>
	<button
		mat-flat-button
		color="warn"
		(click)="closeDialog()">
		Cancel
	</button>
</div>
