<div class="drawer container column center">
	<div class="drawer-content container row">
		<ul class="navigation-links container row space-between">
			<li
				*ngFor="let link of drawerLinks"
				class="nav-link"
				[ngClass]="link.textCol">
				<a
					[routerLink]="link.routerLink"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					[attr.aria-label]="link.aria">
					<mat-icon
						class="icon"
						[svgIcon]="link.icon"></mat-icon
					><span *ngIf="showText">{{ link.label }}</span>
				</a>
			</li>
			<li>
				<button
					*ngIf="authService.isAuthedSubject$ | async"
					class="button-white refresh"
					mat-flat-button
					color="primary"
					(click)="refreshDatabase()">
					<mat-icon
						class="icon"
						svgIcon="rotate"></mat-icon>
				</button>
			</li>
		</ul>
	</div>
	<ng-container
		*ngIf="currentbreakpoint === 'handset' || currentbreakpoint === 'medium'">
		<div class="drawer-bottom container row center">
			<app-user-details></app-user-details>
			<button
				class="logout"
				mat-flat-button
				color="warn"
				routerLink="/auth/logout"
				*ngIf="authService.isAuthedSubject$ | async"
				(click)="logout()">
				Logout
			</button>
		</div>
	</ng-container>
</div>
