<!--
<div class="container column main">
	<mat-tab-group
		animationDuration="0s"
		[selectedIndex]="selectedTabIndex">
		 <mat-tab label="Hand Records">
			<app-hand-tab></app-hand-tab>
		</mat-tab>
		<mat-tab label="Hand Actions">
			<app-hand-actions></app-hand-actions>
		</mat-tab>
		<mat-tab label="Upload">
			<h2>Upload Hand Record Files</h2>

			<div class="container column upload">
				<h4>Upload single hand files (.dlm, .dup or .pbn format)</h4>
				<app-upload-file
					[uploadSuccess]="uploadSuccess"
					[isLoading]="isLoading"
					(upload)="handleFiles($event)"
					(signalAgain)="resetStatus()"></app-upload-file>
			</div>
		</mat-tab>
		<mat-tab label="Download">
			<h2>Download Current</h2>
			<div class="container column upload download">
				<h4>Download the current deal file as .pbn</h4>
				<ng-container *ngIf="!isLoading">
					<button
						class="download"
						mat-flat-button
						color="accent"
						(click)="handleDownload()">
						Download current deal file
					</button>
				</ng-container>
				<ng-container *ngIf="isLoading">
					<mat-spinner></mat-spinner>
				</ng-container>
			</div>
			<ng-container *ngIf="downloadError">
				<div class="container column download-error">
					<h3>There has been an error downloading the latest deal file.</h3>
					<h3>click the button below to try again</h3>
					<button
						mat-flat-button
						color="primary"
						(click)="tryAgain()">
						Try Again
					</button>
				</div>
			</ng-container>
			<ng-container></ng-container>
		</mat-tab>
	-->
<!--
	</mat-tab-group>
</div> -->
<div class="container column center main">
	<div
		class="container row main"
		[ngClass]="currentBreakpoint">
		<div class="container column center left">
			<h3>Upload Hand Record Files</h3>
			<p class="upload-note">
				Please check which deal file is already loaded to the IBEScore App by
				logging into it as the Director and viewing a traveller. Please use your
				file dealer to analyse the hands before uploading here.
			</p>

			<div class="container column upload">
				<h4>Upload single hand files (.dlm, .dup or .pbn format)</h4>
				<app-upload-file
					[uploadSuccess]="uploadSuccess"
					[isLoading]="isLoading"
					(upload)="handleFiles($event)"
					(signalAgain)="resetStatus()"></app-upload-file>
			</div>
		</div>
		<div class="container column center middle">
			<h3>Download Current</h3>
			<div class="container column center upload download">
				<h4>Download the current deal file as .pbn</h4>
				<ng-container *ngIf="!isLoading">
					<button
						class="download"
						mat-flat-button
						color="accent"
						(click)="handleDownload()">
						Download current deal file
					</button>
				</ng-container>
				<ng-container *ngIf="isLoading">
					<mat-spinner></mat-spinner>
				</ng-container>
			</div>
			<ng-container *ngIf="downloadError">
				<div class="container column center download-error">
					<h3>There has been an error downloading the latest deal file.</h3>
					<h3>click the button below to try again</h3>
					<button
						mat-flat-button
						color="primary"
						(click)="tryAgain()">
						Try Again
					</button>
				</div>
			</ng-container>
		</div>
		<div class="container column center right">
			<h3>Delete Current</h3>
			<div class="container column center delete">
				<h4>
					Delete the current hand record file. This will clear all current hand
					records.
				</h4>
				<button
					mat-flat-button
					color="warn"
					(click)="deleteHand()">
					Delete
				</button>
			</div>
		</div>
	</div>
</div>
