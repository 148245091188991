<div class="main">
	<div class="tabs container">
		<ul class="tab-headers">
			<li
				#tabHeader
				class="tab-header hand-records"
				tabname="handRecords"
				(click)="onTabClick($event)">
				<span class="active"> <h2>Hand Records</h2> </span>
			</li>
			<li
				#tabHeader
				class="tab-header bridge-webs"
				tabname="bridgeWebs"
				(click)="onTabClick($event)">
				<span><h2>BridgeWebs</h2></span>
			</li>
		</ul>
	</div>
	<div class="tab-content">
		<ng-container *ngTemplateOutlet="activeTab"></ng-container>
	</div>
</div>

<ng-template templateName="handRecords">
	<div
		class="container column center"
		[ngClass]="currentBreakpoint">
		<h3>Download the game file</h3>
		<div class="container row button-container">
			<button
				mat-flat-button
				color="primary"
				(click)="openEbu()">
				XML
			</button>

			<button
				mat-flat-button
				color="primary"
				(click)="downloadBridgeWebs()">
				CSV
			</button>
			<button
				mat-flat-button
				color="primary"
				(click)="openHtmlPdf()">
				<!-- HTML/PDF -->
				HTML
			</button>
		</div>
		<div class="container column center"></div>
	</div>
</ng-template>

<ng-template
	#bridgewebs
	templateName="bridgeWebs">
	<div class="container bw-form">
		<form [formGroup]="bridgewebsGameDetailsForm">
			<div class="container game-details">
				<h3>Game Details</h3>
				<mat-form-field>
					<mat-label> Event Name </mat-label>
					<input
						matInput
						formControlName="bwEventName" />
				</mat-form-field>
				<mat-form-field>
					<mat-label> Director Name </mat-label>
					<input
						matInput
						formControlName="bwDirectorName" />
				</mat-form-field>
				<mat-form-field>
					<mat-label> Scorer Name </mat-label>
					<input
						matInput
						formControlName="bwScorerName" />
				</mat-form-field>
				<div class="container column checkbox-col">
					<mat-checkbox formControlName="bwMasterpoints"
						>Include Masterpoints</mat-checkbox
					>
					<ng-container *ngIf="bridgeWebsMasterPoints">
						<mat-checkbox formControlName="masterpointsMatchWon"
							>Masterpoints per match won</mat-checkbox
						>
					</ng-container>
				</div>
			</div>

			<ng-container *ngIf="true">
				<div class="download container button-wrapper row">
					<ng-container *ngIf="!bwStatus.downloading">
						<button
							mat-flat-button
							color="accent"
							(click)="downloadBridgeWebs()"
							[disabled]="!bridgewebsGameDetailsForm.valid">
							Download
						</button>
					</ng-container>
					<ng-container *ngIf="bwStatus.downloading">
						<mat-spinner
							color="accent"
							class="download"></mat-spinner>
					</ng-container>
					<button
						mat-flat-button
						[disabled]="bridgewebsGameDetailsForm.pristine"
						color="warn"
						(click)="clearBwGameDetails()">
						Reset
					</button>
				</div>
			</ng-container>
		</form>
		<form [formGroup]="bridgewebsAccountDetailsForm">
			<div class="container account-details">
				<h3>BridgeWebs Account</h3>
				<mat-form-field>
					<mat-label>Username</mat-label>
					<input
						matInput
						formControlName="bwAccountName" />
				</mat-form-field>
				<mat-form-field>
					<mat-label>Password</mat-label>
					<input
						matInput
						formControlName="bwPassword" />
				</mat-form-field>
			</div>
			<div class="container row button-wrapper">
				<div class="upload">
					<ng-container *ngIf="!bwStatus.uploading">
						<button
							mat-flat-button
							color="primary"
							(click)="uploadBridgeWebs()"
							[disabled]="
								!bridgewebsAccountDetailsForm.valid ||
								!bridgewebsGameDetailsForm.valid
							">
							Upload
						</button>
					</ng-container>
					<ng-container *ngIf="bwStatus.uploading">
						<mat-spinner class="upload"></mat-spinner>
					</ng-container>
				</div>

				<button
					mat-flat-button
					[disabled]="bridgewebsAccountDetailsForm.pristine"
					color="warn"
					(click)="clearBwAccountDetails()">
					Reset
				</button>
			</div>
		</form>
	</div>
</ng-template>

<!-- <ng-container *ngIf="currentBreakpoint !== 'handset'">
	<div class="container row main">
		<div class="container column bridgewebs">
			<h3>BridgeWebs</h3>
			<form [formGroup]="bridgewebsGameDetailsForm">
				<div class="container column form">
					<h3>Upload to BridgeWebs</h3>
					<mat-form-field>
						<mat-label>Event Name:</mat-label>
						<input
							matInput
							formControlName="eventName" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Director Name:</mat-label>
						<input
							matInput
							formControlName="directorName" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Scorer Name:</mat-label>
						<input
							matInput
							formControlName="scorerName" />
					</mat-form-field>
					<mat-checkbox formControlName="masterpoints">Master points?</mat-checkbox>
					<ng-container *ngIf="bridgeWebsMasterPoints">
						<mat-checkbox formControlName="masterpointsMatchwon"
							>Master points per match won</mat-checkbox
						>
					</ng-container>

					<mat-form-field>
						<mat-label>BridgeWebs Account:</mat-label>
						<input
							matInput
							formControlName="bridgeWebsAccount" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Password:</mat-label>
						<input
							matInput
							formControlName="password" />
					</mat-form-field>
					<div class="container column button-wrapper">
						<button
							mat-flat-button
							color="primary"
							(click)="uploadBridgeWebs()">
							Upload
						</button>
					</div>
					<div class="container column button-wrapper">
						<h3>Download BridgeWebs file to your device</h3>
						<button
							mat-flat-button
							color="accent"
							(click)="downloadBridgeWebs()">
							Download
						</button>
					</div>
				</div>
			</form>
		</div>
		<div class="container column ebu">
			<h3>P2P</h3>
			<form formGroupName="P2PForm">
				<mat-form-field>
					<mat-label> </mat-label>
					<input
						matInput
						formControlName="" />
				</mat-form-field>
			</form>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="currentBreakpoint === 'handset'"> </ng-container> -->
