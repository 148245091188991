<ng-container *ngIf="loadingStatus !== 100">
	<div class="container column loading">
		<h2>Loading...</h2>
		<mat-progress-bar
			*ngIf="loadingStatus !== 100"
			mode="determinate"
			[value]="loadingStatus"></mat-progress-bar>
	</div>
</ng-container>
<ng-container
	*ngIf="(authService.isAuthedSubject$ | async) && loadingStatus === 100">
	<ng-container *ngIf="!superAdmin">
		<app-navigation></app-navigation>
	</ng-container>
	<ng-container *ngIf="superAdmin">
		<ng-container
			><div class="container column center current-label">
				<h1 class="breadcrumb">
					{{ currentLabel }}
				</h1>
			</div></ng-container
		>
		<router-outlet></router-outlet>
	</ng-container>
</ng-container>
