<div class="main container column">
	<mat-tab-group>
		<mat-tab
			class="players"
			label="Players">
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="playersDatabase"></ng-container>
			</ng-template>
		</mat-tab>
		<mat-tab
			class="teams"
			label="Teams">
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="teamsDatabase"></ng-container>
			</ng-template>
		</mat-tab>
		<mat-tab
			class="event-names"
			label="Event Names">
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="eventNamesDatabase"></ng-container>
			</ng-template>
		</mat-tab>
		<mat-tab
			class="Venues"
			label="Venues">
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="venuesDatabase"></ng-container>
			</ng-template>
		</mat-tab>
		<!-- <mat-tab
			class="import-export"
			label="Advanced">
			<ng-container *ngTemplateOutlet="importExport"></ng-container>
		</mat-tab> -->
	</mat-tab-group>
	<!-- <div
		class="container row options"
		style="gap: 2rem">
		<button
			mat-flat-button
			(click)="onClickOptions()"
			color="accent">
			Advanced
		</button>
	</div> -->
</div>

<!-- Template References -->
<ng-template #playersDatabase>
	<!-- Players Database Tab -->
	<!-- <mat-spinner *ngIf="playersArray.length < 1"></mat-spinner> -->

	<app-player-database></app-player-database>
</ng-template>
<ng-template #teamsDatabase>
	<!-- Teams Database Tab -->
	<app-teams-database></app-teams-database>
</ng-template>
<ng-template #eventNamesDatabase>
	<!-- Event Names Database Tab -->
	<app-event-names-database></app-event-names-database>
</ng-template>
<ng-template #venuesDatabase>
	<app-venues-database></app-venues-database>
	<!-- Venues Database Tab -->
</ng-template>
<ng-template #importExport>
	<app-import-export></app-import-export>
</ng-template>
