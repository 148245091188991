<form [formGroup]="scoringConfigForm">
	<div class="grid-container">
		<div class="grid-row header-row">
			<div class="grid-item header">Event Type</div>
			<div class="grid-item header">Default</div>
			<div class="grid-item header">Preferred Duration</div>
			<div class="grid-item header">Scoring Method</div>
			<!-- <div class="grid-row item"> -->
		</div>
		<div
			*ngFor="let control of scoringDataArray.controls; let i = index"
			class="grid-row">
			<!-- <div
				class="form-wrapper"
				[formGroupName]="i"> -->
			<div class="grid-item label">
				<span>{{ scoringData[i].eventType }}</span>
			</div>

			<mat-checkbox
				class="grid-item checkbox"
				[formControl]="getControl(i, 'defaultSelected') | formControl">
			</mat-checkbox>

			<mat-form-field class="grid-item">
				<mat-select
					[formControl]="getControl(i, 'preferredDuration') | formControl">
					<mat-option
						*ngFor="let option of scoringData[i].preferredDuration"
						[value]="option.value"
						>{{ option.display }}</mat-option
					>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="grid-item dropdown">
				<mat-select [formControl]="getControl(i, 'scoringMethods') | formControl">
					<mat-option
						*ngFor="let method of scoringData[i].scoringMethods"
						[value]="method.value"
						>{{ method.display }}</mat-option
					>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="scoring container column">
			<mat-form-field>
				<mat-label>Use Neuberg Correction</mat-label>
				<mat-select formControlName="neuberg">
					<mat-option value="interim">Interim and final scores</mat-option>
					<mat-option value="final">Final scores only</mat-option>
					<mat-option value="never">never</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Default number of tables</mat-label>
				<mat-select formControlName="tables">
					<mat-option value="3">3</mat-option>
					<mat-option value="4">4</mat-option>
					<mat-option value="5">5</mat-option>
					<mat-option value="6">6</mat-option>
					<mat-option value="7">7</mat-option>
					<mat-option value="8">8</mat-option>
					<mat-option value="9">9</mat-option>
					<mat-option value="10">10</mat-option>
					<mat-option value="11">11</mat-option>
					<mat-option value="12">12</mat-option>
					<mat-option value="13">13</mat-option>
					<mat-option value="14">14</mat-option>
					<mat-option value="15">15</mat-option>
					<mat-option value="16">16</mat-option>
					<mat-option value="17">17</mat-option>
					<mat-option value="18">18</mat-option>
					<mat-option value="19">19</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<button
			mat-flat-button
			(click)="getBoardsScoringValues()"
			color="primary">
			{{ getButtonMessage() ? 'Success' : 'Save' }}
		</button>
	</div>
</form>
