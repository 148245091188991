<div
	class="main container column center"
	[ngClass]="currentBreakpoint">
	<ng-container *ngIf="local">
		<div class="iframe">
			<iframe
				#iframe
				src="https://ibescore.com/companion-information-help-local/"
				width="100%"
				height="900px"
				frameborder="0"></iframe>
		</div>
	</ng-container>
	<ng-container *ngIf="!local && !dev">
		<div class="iframe">
			<iframe
				#iframe
				src="https://ibescore.com/companion-information-help/"
				width="100%"
				height="900px"
				frameborder="0"></iframe>
		</div>
	</ng-container>
	<ng-container *ngIf="!local && dev">
		<div class="iframe">
			<iframe
				#iframe
				src="https://ibescore.com/companion-information-help-dev/"
				width="100%"
				height="900px"
				frameborder="0"></iframe>
		</div>
	</ng-container>
</div>
