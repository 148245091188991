<div class="container">
	<mat-drawer-container
		class="sidenav"
		autosize>
		<!-- <button
		type="button"
		mat-flat-button
		color="primary"
		(click)="drawer.toggle()"></button> -->
		<mat-drawer
			class="drawer"
			#drawer
			opened="false"
			mode="over">
			<div class="nav-links column">
				<a
					routerLink="/admin/welcome-members"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Members Area</a
				>
				<a
					routerLink="/admin/game-setup"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Game Code Settings</a
				>
				<a
					routerLink="/admin/games"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Game In Progress</a
				>
				<a
					routerLink="/admin/account"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Account Settings</a
				>
				<a
					routerLink="/admin/hand-records"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Hand Records</a
				>
				<a
					routerLink="/admin/reporting"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Reporting</a
				>
				<a
					routerLink="/admin/historic-games"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Historic Games</a
				>
				<a
					routerLink="/admin/player-database"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Player Database</a
				>
				<a
					routerLink="/admin/database-admin"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Database Admin</a
				>
				<a
					routerLink="/admin/admin-tools"
					[routerLinkActive]="['active-link']"
					class="nav-link"
					(click)="handleLinkClick()"
					>Admin Tools</a
				>
			</div>
			<ng-container
				*ngIf="currentBreakpoint === 'handset' || currentBreakpoint === 'medium'">
				<div class="container column center user-details">
					<h3>Game Code</h3>
					<span class="gamecode">{{ gameCode }}</span>
					<button
						mat-flat-button
						color="warn"
						(click)="handleLogout()">
						Logout
					</button>
				</div>
			</ng-container>
		</mat-drawer>
		<mat-drawer-content
			class="content"
			[ngClass]="contentClass">
			<!-- main application content -->
			<ng-container
				><div class="container column center current-label">
					<h2 class="breadcrumb">
						{{ currentLabel }}
					</h2>
				</div></ng-container
			>
			<router-outlet></router-outlet>
		</mat-drawer-content>
	</mat-drawer-container>
</div>
