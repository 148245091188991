<div class="main">
	<div class="container row search">
		<mat-form-field [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
			<mat-label>Type to search historic games</mat-label>
			<input
				#input
				matInput
				(keyup)="applyFilter($event)" />
		</mat-form-field>
	</div>
	<div
		class="spinner"
		*ngIf="isLoading">
		<mat-spinner></mat-spinner>
	</div>
	<table
		[hidden]="!dataSource"
		[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
		class="mat-elevation-z8"
		matSort
		mat-table
		[dataSource]="dataSource">
		<!-- Number Column -->
		<ng-container matColumnDef="number">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header="number">
				#
			</th>
			<td
				mat-cell
				*matCellDef="let game">
				{{ game?.number + 1 || '' }}
			</td>
		</ng-container>
		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header="name">
				Name
			</th>
			<td
				mat-cell
				*matCellDef="let game">
				{{ game?.name || '' }}
			</td>
		</ng-container>
		<!-- Date Column -->
		<ng-container matColumnDef="date">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header="date">
				Date
			</th>
			<td
				mat-cell
				*matCellDef="let game">
				{{ game?.date || '' }}
			</td>
		</ng-container>
		<!-- Delete Column -->
		<ng-container matColumnDef="delete">
			<th
				mat-header-cell
				*matHeaderCellDef>
				Delete Game
			</th>
			<td
				class="delete"
				mat-cell
				*matCellDef="let game"
				(mouseenter)="deleteHover(game)"
				(mouseleave)="deleteHover(game)"
				(click)="onDeleteClicked($event, game)">
				<mat-icon
					[ngClass]="game.deleteHovered ? 'active' : ''"
					(click)="onDeleteClicked($event, game)"
					>delete</mat-icon
				>
			</td>
			<tr
				class="header-row"
				mat-header-row
				*matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				(click)="onRowClick(row)"
				*matRowDef="let row; columns: displayedColumns"></tr>
			<tr
				class="table-row"
				mat-row
				*matNoDataRow>
				<td
					mat-cell
					colspan="4">
					No games matching your search term "{{ input.value }}"
				</td>
			</tr>
		</ng-container>
	</table>
	<ng-container *ngIf="dataSource">
		<mat-paginator
			#paginator
			class="mat-elevation-z8"
			[pageSizeOptions]="[20, 40, 100, dataSource.data.length]"
			showFirstLastButtons
			aria-label="Select page of historic games">
		</mat-paginator>
	</ng-container>
</div>
