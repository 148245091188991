<ul class="navigation-links container row space-between">
	<li
		*ngFor="let link of topBarLinks"
		class="nav-link"
		[ngClass]="link.textCol">
		<a
			[routerLink]="link.routerLink"
			[routerLinkActive]="['active-link']"
			class="nav-link"
			(click)="handleLinkClick()"
			[attr.aria-label]="link.aria">
			<mat-icon
				class="icon"
				[svgIcon]="link.icon"></mat-icon>
			<span *ngIf="showText">{{ link.label }}</span>
		</a>
	</li>
</ul>
